import * as React from 'react';
import { ThemeProvider, PartialTheme } from "@fluentui/react";
import { Layout } from './layouts/Layout';
import './assets/styles/app.scss';

const appTheme: PartialTheme = {
    palette: {
      themePrimary: 'red'
    }
  };

export const App: React.FunctionComponent = () => {
   return(
      <ThemeProvider theme={appTheme}>
          <Layout />
      </ThemeProvider>
  )
}