import * as React from 'react';
import { Stack} from '@fluentui/react';
import { Header } from "../layouts/Header";
import { Content } from "../layouts/Content";
import UserApi from '../services/UserApi'
import { useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/auth-provider";
import { useSessionStorage } from '../services/SessionStorage';
import { useTranslation } from "react-i18next";

export const Layout: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const { instance } = useMsal();
  const [isAdmin, setIsAdmin] = useSessionStorage("userIsAdmin",false);

  React.useEffect(() => {
    getIsAdmin();
  },[instance]);

  async function getIsAdmin() {
    var acc = instance.getActiveAccount();
    if(acc){
      var queryString = "?";
      await instance.acquireTokenSilent({
          ...tokenRequest,
          account: acc
      }).then((response) =>{
        const config = {
          headers: { Authorization: `Bearer ${response.accessToken}` }
        };

        if (acc?.idTokenClaims?.hasOwnProperty("groups")) {
          const groupsStr = acc.idTokenClaims["groups"] as string[];
          groupsStr.forEach(group => {
            queryString += "groupIds=" + group + "&";
          });
        }

        UserApi
        .get("/isadmin" + queryString.slice(0, -1), config)
        .then((res) => {
          if(res.data){
            setIsAdmin(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsAdmin(false);
        })
      });
    } 
  }

    return (
        <>
            <Stack>
                <Header isAdmin={isAdmin} />
            </Stack>
            <Stack className="mainTitle">
                <h1 className="titleText highlightText">{t('portal')}</h1>
            </Stack>
            <Stack>
                <Content isAdmin={isAdmin} />
            </Stack>
        </>
    );
};