import * as React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { AppCardList } from "../components/app-card-list";
import { Redirect } from "react-router";

export const DashboardPage: React.FunctionComponent = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <AppCardList />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Redirect to="/" />
      </UnauthenticatedTemplate>
    </>
  );
};
