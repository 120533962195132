import * as React from "react";
import { Guid } from "guid-typescript";
import { IGroup } from "../interfaces/IGroup";
import { IAppDto } from "../interfaces/IAppDto";
import { IApp } from "../interfaces/IApp";
import {
  TextField,
  Stack,
  Image,
  IImageProps,
  ImageFit,
  IIconProps,
  PrimaryButton,
  ActionButton,
  DefaultButton,
  IStackTokens,
  MessageBar,
  MessageBarType,
  Dropdown,
  IDropdownOption,
} from "@fluentui/react";
import AppApi from "../services/AppApi";
import AppGroupsApi from "../services/AppGroupsApi";
import { useMsal } from "@azure/msal-react";
import { adminTokenRequest } from "../auth/auth-provider";
import GroupsApi from "../services/GroupsApi";

const SuccessMessage = () => (
  <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
    Changes successfully saved.
  </MessageBar>
);
const ErrorMessage = (props) => (
  <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
    {props.message}
  </MessageBar>
);

export const AppDetails: React.FunctionComponent<IApp> = (props: IApp) => {
  const [message, setMessage] = React.useState<string | undefined>(undefined);
  const resetMessage = React.useCallback(() => setMessage(undefined), []);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [app, setApp] = React.useState<IAppDto>({
    title: props.title,
    description: props.description,
    url: props.url,
  });
  const [groups, setGroups] = React.useState<IGroup[]>([]);
  const [selectedGroups, setSelectedGroups] = React.useState<string[]>([]);
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();
  if (accounts.length > 0) {
    instance.setActiveAccount(accounts[0]);
  }

  React.useEffect(() => {
    setApp({
      title: props.title,
      description: props.description,
      url: props.url,
    });
    setPreviewImage(
      process.env.REACT_APP_APPSIMAGE_API_PATH + "/app/" + props.appId
    );

    async function getGroups() {
      var acc = instance.getActiveAccount();
      if (acc) {
        var token = await instance
          .acquireTokenSilent({
            ...adminTokenRequest,
            account: acc,
          })
          .then((response) => {
            return response.accessToken;
          });
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        GroupsApi.get("/", config).then((res) => {
          setGroups(res.data);
        });
      }
    }
    async function getSelectedGroups() {
      var acc = instance.getActiveAccount();
      if (acc) {
        var token = await instance
          .acquireTokenSilent({
            ...adminTokenRequest,
            account: acc,
          })
          .then((response) => {
            return response.accessToken;
          });
        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };
        AppGroupsApi.get("/app/" + props.appId.toString(), config).then(
          (res) => {
            setSelectedGroups(res.data);
          }
        );
      }
    }

    getGroups();
    getSelectedGroups();
  }, [props.title, props.description, props.url, props.appId]);

  async function handleSubmit(evt) {
    evt.preventDefault();
    
    let fd = new FormData();
    fd.append("title", app.title);
    fd.append("description", app.description);
    fd.append("url", app.url);
    fd.append("image_file", app.image?.data);
    fd.append("image_name", app.image?.name);

    var acc = instance.getActiveAccount();
    if(acc){
        var token = 
          await instance.acquireTokenSilent({
          ...adminTokenRequest,
          account: acc
        }).then((response) =>{
            return  response.accessToken;
        });
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };
    if (!props.appId.isEmpty) {
      fd.append("appId", props.appId.toString());
      AppApi.put("/", fd, config)
        .then(() => {
          var queryString = "?";
          selectedGroups.forEach((group) => {
            queryString += "groupIds=" + group + "&";
          });
          AppGroupsApi.post(
            "/app/" + props.appId.toString() + queryString.slice(0, -1), null,
            config
          );
        })
        .then(() => {
          setIsDirty(false);
          setMessage("success");
        })
        .catch((error) => {
          console.error(error.message);
        });
    } else {
      fd.append("selected_Groups", selectedGroups.join(","))
      AppApi.post("/", fd, config)
        .then(() => {
          setIsDirty(false);
          setMessage("success");
          finishEditing();
        })
        .catch((error) => {
          console.error(error.message);
        });
      }
    }
  }

  const onTitleChange = React.useCallback(
    (
      e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      e.persist();
      setIsDirty(true);
      resetMessage();
      setApp((prevApp) => ({ ...prevApp, title: newValue || "" }));
    },
    []
  );

  const onUrlChange = React.useCallback(
    (
      e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      e.persist();
      setIsDirty(true);
      resetMessage();
      setApp((prevApp) => ({ ...prevApp, url: newValue || "" }));
    },
    []
  );

  const onDescriptionChange = React.useCallback(
    (
      e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      newValue?: string
    ) => {
      e.persist();
      setIsDirty(true);
      resetMessage();
      setApp((prevApp) => ({ ...prevApp, description: newValue || "" }));
    },
    []
  );

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    if (e.target.files) {
      let file = e.target.files[0];
      setPreviewImage(URL.createObjectURL(file));

      setApp((prevApp) => ({
        ...prevApp,
        image: { data: file, name: file.name },
      }));
      setIsDirty(true);
      resetMessage();
    }
  };

  const onGroupChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    if (item) {
      setIsDirty(true);
      resetMessage();
      setSelectedGroups(
        item.selected
          ? [...selectedGroups, item.key as string]
          : selectedGroups.filter((key) => key !== item.key)
      );
    }
  };

  const buttonStackTokens: IStackTokens = { childrenGap: 40 };
  const fieldStackTokens: IStackTokens = { childrenGap: 15 };

  const imageProps: IImageProps = {
    imageFit: ImageFit.contain,
  };
  const addImageAddIcon: IIconProps = { iconName: "Photo2Add" };
  const addImageDeleteIcon: IIconProps = { iconName: "Photo2Remove" };
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  function handleImageUploadClick() {
    fileInputRef.current?.click();
  }

  function handleImageDeleteClick() {
    setPreviewImage("");
    setApp((prevApp) => ({
      ...prevApp,
      image: { data: "", name: "" },
    }));
    setIsDirty(true);
    resetMessage();
  }

  function onImageError() {
    setPreviewImage("");
  }

  return (
    <form onSubmit={ (e) => handleSubmit(e)}>
      <Stack tokens={fieldStackTokens}>
        {/* <TextField disabled label="Id" value={props.appId?.toString()} /> */}
        <TextField
          label="Title"
          value={app.title}
          onChange={onTitleChange}
          required
        />
        <TextField
          label="Description"
          value={app.description}
          onChange={onDescriptionChange}
        />
        <TextField
          type="url"
          label="Url"
          value={app.url}
          onChange={onUrlChange}
          required
        />
        <Dropdown
          label="Groups"
          multiSelect
          onChange={onGroupChange}
          selectedKeys={selectedGroups}
          options={groups.map(({ groupId, name }) => ({
            key: groupId.toString(),
            text: name,
          }))}
        />
      </Stack>
      <br />
      <Stack horizontal tokens={buttonStackTokens}>
        <ActionButton
          iconProps={addImageAddIcon}
          onClick={handleImageUploadClick}
          label="Upload Image"
        >
          Upload Image
        </ActionButton>
        <ActionButton
          iconProps={addImageDeleteIcon}
          onClick={handleImageDeleteClick}
          label="Delete Image"
          disabled={
            previewImage.length === 0 || previewImage.endsWith(Guid.EMPTY)
          }
        >
          Delete Image
        </ActionButton>
      </Stack>
      <Stack>
        <input
          ref={fileInputRef}
          style={{ display: "none" }}
          type="file"
          accept="image/png, image/jpeg, image/svg+xml"
          onChange={onImageChange}
        />
        <Image
          {...imageProps}
          height={200}
          src={previewImage}
          alt="image_preview"
          onError={onImageError}
        />
      </Stack>
      <hr />
      <Stack>
        {message === "success" && <SuccessMessage />}
        {message === "error" && <ErrorMessage message="error" />}
      </Stack>
      <Stack horizontal tokens={buttonStackTokens}>
        <PrimaryButton type="submit" disabled={!isDirty}>
          Save
        </PrimaryButton>
        <DefaultButton onClick={finishEditing}>Back</DefaultButton>
      </Stack>
    </form>
  );
};

function finishEditing(): void {
  window.location.href = "/Admin/Apps";
}

