import * as React from "react";
import {
  useMsal
} from "@azure/msal-react";
import {AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import {
  ActionButton,
  DefaultButton,
  Persona,
  PersonaSize,
  PersonaInitialsColor,
  Callout,
  mergeStyleSets,
  getTheme,
  FontWeights,
  IContextualMenuProps,
  Stack,
  DirectionalHint,
} from "@fluentui/react";

import { useBoolean } from "@fluentui/react-hooks";
import { loginRequest } from "../auth/auth-provider";
import { useTranslation } from "react-i18next";

//MENU
import { ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';
import { useConst } from '@fluentui/react-hooks';
import i18next from "i18next";

const theme = getTheme();
const styles = mergeStyleSets({
  buttonArea: {
    verticalAlign: "top",
    display: "inline-block",
    textAlign: "center",
    minWidth: 130,
    height: 48,
  },
  callout: {
    maxWidth: 300,
  },
  header: {
    padding: "18px 24px 12px",
  },
  title: [
    theme.fonts.xLarge,
    {
      margin: 0,
      fontWeight: FontWeights.semilight,
    },
  ],
  inner: {
    height: "100%",
    padding: "0 24px 20px",
  },
  actions: {
    position: "relative",
    marginTop: 20,
    width: "100%",
    whiteSpace: "nowrap",
  },
  subtext: [
    theme.fonts.small,
    {
      margin: 0,
      fontWeight: FontWeights.semilight,
    },
  ],
  link: [
    theme.fonts.medium,
    {
      color: theme.palette.neutralPrimary,
    }
  ],
});

export const PersonButton: React.FunctionComponent<{userIsAdmin:Boolean}> = (props) => {

  const { t, i18n } = useTranslation();
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();


  if (accounts.length > 0) {
    instance.setActiveAccount(accounts[0]);
  }

  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(
    false
  );
  
  const [isLanguageMenuVisible, { setTrue: setIsLanguageMenuVisible, setFalse: setIsLanguageMenuHidden }] = useBoolean(
    false
  );

  const adminMenuProps: IContextualMenuProps = {
    items: [
      {
        key: 'appList',
        text: 'Application List',
        iconProps: { iconName: 'AppIconDefaultList' },
        href: '/Admin/Apps',
      },
      {
        key: 'returnDashboard',
        text: 'Return to dashbaord',
        iconProps: { iconName: 'AppIconDefaultList' },
        href: '/',
      },
    ],
  };

  const languageMenuProps: IContextualMenuProps = {
    items: [
      {
        key: 'en',
        text: i18n.getResource('en', 'translation', '_name'),
        href: window.location.pathname + '/?lng=en',
        isChecked: i18n.language==='en'?true:false,
      },
      {
        key: 'de',
        text: i18n.getResource('de', 'translation', '_name'),
        href: window.location.pathname + '/?lng=de',
        canCheck: true, 
        isChecked: i18n.language==='de'?true:false,
      },
      {
        key: 'cn',
        text: i18n.getResource('cn', 'translation', '_name'),
        href: window.location.pathname + '/?lng=cn',
        canCheck: true, 
        isChecked: i18n.language==='cn'?true:false,
      },
      {
        key: 'es',
        text: i18n.getResource('es', 'translation', '_name'),
        href: window.location.pathname + '/?lng=es',
        canCheck: true, 
        isChecked: i18n.language==='es'?true:false,
      },
      {
        key: 'fr',
        text: i18n.getResource('fr', 'translation', '_name'),
        href: window.location.pathname + '/?lng=fr',
        canCheck: true, 
        isChecked: i18n.language==='fr'?true:false,
      },
      {
        key: 'it',
        text: i18n.getResource('it', 'translation', '_name'),
        href: window.location.pathname + '/?lng=it',
        canCheck: true, 
        isChecked: i18n.language==='it'?true:false,
      },
      {
        key: 'ru',
        text: i18n.getResource('ru', 'translation', '_name'),
        href: window.location.pathname + '/?lng=ru',
        canCheck: true, 
        isChecked: i18n.language==='ru'?true:false,
      },
      {
        key: 'tr',
        text: i18n.getResource('tr', 'translation', '_name'),
        href: window.location.pathname + '/?lng=tr',
        canCheck: true, 
        isChecked: i18n.language==='tr'?true:false,
      },
      {
        key: 'zh',
        text: i18n.getResource('zh', 'translation', '_name'),
        href: window.location.pathname + '/?lng=zh',
        canCheck: true, 
        isChecked: i18n.language==='zh'?true:false,
      },
      {
        key: 'et',
        text: i18n.getResource('et', 'translation', '_name'),
        href: window.location.pathname + '/?lng=et',
        canCheck: true, 
        isChecked: i18n.language==='et'?true:false,
      },
      

      { 
        key: 'divider_auto', 
        itemType: ContextualMenuItemType.Divider
      },
      {
        key: 'auto',
        text: t('auto'),
        href: '/dashboard',
      },      
    ],
    directionalHint: DirectionalHint.leftTopEdge,
    isBeakVisible: true,
  };

  return (
    <>
      <AuthenticatedTemplate>
        <div className={styles.buttonArea}>
          <Persona
            text={instance.getActiveAccount()?.name ? `${instance.getActiveAccount()?.name}` : ""}
            secondaryText={instance.getActiveAccount()?.idTokenClaims?.['signInName'] ? `${instance.getActiveAccount()?.idTokenClaims?.['signInName']}` : ""}
            initialsColor={PersonaInitialsColor.lightRed}
            size={PersonaSize.size48}
            onClick={toggleIsCalloutVisible}
          />
        </div>
        {isCalloutVisible && (
          <Callout
            className={styles.callout}
            role="alertdialog"
            gapSpace={0}
            target={`.${styles.buttonArea}`}
            onDismiss={toggleIsCalloutVisible}
            setInitialFocus
          >
            <Stack>
              {props.userIsAdmin && (
                <DefaultButton
                  text="Administration"
                  primary
                  split
                  menuProps={adminMenuProps}
                />
              )}
              <ActionButton
                iconProps={{ iconName: "Signout" }}
                onClick={() => instance.logout()}
                className="ml-auto"
              >
                {t('logout')}
              </ActionButton>

              <DefaultButton
                  text={t('language')}
                  iconProps={{ iconName: "LocaleLanguage" }}
                  menuProps={languageMenuProps}
              />

            </Stack>
          </Callout>
        )}
       </AuthenticatedTemplate>
       <UnauthenticatedTemplate>
          <ActionButton
            iconProps={{ iconName: "Signin" }}
            onClick={() => instance.loginRedirect(loginRequest)}
            className="ml-auto"
          >
            {t('login')}
          </ActionButton>
        </UnauthenticatedTemplate>
        </>
  )

};