import * as React from "react";
import { Redirect } from "react-router";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Guid } from "guid-typescript";
import { AppDetails } from "../components/app-details";
import { IApp } from "../interfaces/IApp";


export const AppNewPage: React.FunctionComponent<{userIsAdmin:Boolean}> = (props) => {

  const app : IApp = {
    appId: Guid.createEmpty(),
    title: "",
    url: "",
    description: "",
  }
 

  return (
    <>
      <AuthenticatedTemplate>
        {props.userIsAdmin ? (
          <>
            <h1>New App</h1>
            <AppDetails
              appId={app?.appId}
              title={app?.title}
              url={app?.url}
              description={app.description}
            />
          </>
          ):<Redirect to="/" />
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Redirect to="/" />
      </UnauthenticatedTemplate>
    </>
  );
};
