import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react';
import { EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { loginRequest, msalConfig, passwordResetRequest } from "./auth/auth-provider";
import { App } from './App';

import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";


import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import common_cn from "./translations/cn/common.json";
import common_es from "./translations/es/common.json";
import common_fr from "./translations/fr/common.json";
import common_it from "./translations/it/common.json";
import common_ru from "./translations/ru/common.json";
import common_tr from "./translations/tr/common.json";
import common_zh from "./translations/zh/common.json";
import common_et from "./translations/et/common.json";

export const msalInstance = new PublicClientApplication(msalConfig);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false }, 
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'lng',
    },
    fallbackLng: 'en',
    supportedLngs: ['de', 'en', 'cn', 'es', 'fr', 'it', 'ru', 'tr', 'zh', 'et'],    
    resources: {
      'en': {
          translation: common_en
      },
      'de': {
          translation: common_de
      },
      'cn': {
        translation: common_cn
      },   
      'es': {
          translation: common_es
      },
      'fr': {
          translation: common_fr
      },
      'it': {
          translation: common_it
      },
      'ru': {
          translation: common_ru
      },
      'tr': {
          translation: common_tr
      }, 
      'zh': {
          translation: common_zh
      },            
      'et': {
          translation: common_et
      }            
    },
  });

msalInstance.handleRedirectPromise()
    .then((tokenResponse) => {
        if (!tokenResponse) {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                console.log("No user signed in")
                msalInstance.loginRedirect(loginRequest);
            }
        } else {
          console.log("Whatever")
        }
    })
    .catch(err => {
        // Handle error
        console.error(err);
    });

const instanceCallbackId = msalInstance.addEventCallback((message: EventMessage) => {
  if (message.eventType === EventType.LOGIN_FAILURE) {
    if (message.error?.message.includes("AADB2C90118")) {
      msalInstance.loginRedirect(passwordResetRequest);
    }
  }
})


initializeIcons();

ReactDOM.render(
  <ThemeProvider>
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root')
);