import * as React from "react";
import { AppList } from "../components/app-list";
import {AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Redirect } from "react-router";

export const AdminListPage: React.FunctionComponent<{userIsAdmin:Boolean}> = (props) => {
  
  return (
    <>
      <AuthenticatedTemplate>
        {props.userIsAdmin ? (
          <AppList />
        ):<Redirect to="/" />
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Redirect to="/" />
      </UnauthenticatedTemplate>
    </> 
  )
};
