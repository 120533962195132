import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Guid } from "guid-typescript";
import { Home } from '../pages/Home';
import { DashboardPage } from '../pages/DashboardPage';
import { AdminListPage } from '../pages/AdminListPage';
import { AppNewPage } from '../pages/AppNewPage';
import { AppDetailsPage } from '../pages/AppDetailsPage';
import { useTranslation } from "react-i18next";

export const Content: React.FunctionComponent<{isAdmin:Boolean}> = (props) => {
    
    const { t } = useTranslation();

    return (
        <div className="content">
            <div className="titleNote">{t('note')}</div>
            <BrowserRouter>
                <Route exact path="/" component={Home} />
                <Route path="/Dashboard" component={DashboardPage} />
                <Route exact path="/Admin" render={() => (
                    <AdminListPage userIsAdmin={props.isAdmin} />
                )} />
                <Route exact path="/Admin/New" render={() => (
                    <AppNewPage userIsAdmin={props.isAdmin} />
                )} />
                <Route path="/Admin/Apps/:appId" render={(rcprops) => (
                    <AppDetailsPage appId={Guid.parse(rcprops.match.params.appId)} userIsAdmin={props.isAdmin} />
                )} />
                <Route exact path="/Admin/Apps" render={() => (
                    <AdminListPage userIsAdmin={props.isAdmin} />
                )} />
            </BrowserRouter>
        </div>
    )
}