import * as React from "react";
import {
  CommandBar,
  IColumn,
  ICommandBarItemProps,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton
} from "@fluentui/react";
import {
  DetailsList,
  Image,
  ImageFit,
  Link,
  MarqueeSelection,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import AppApi from '../services/AppApi'
import { IApp } from "../interfaces/IApp";
import { Guid } from "guid-typescript";
import { useMsal } from "@azure/msal-react";
import { adminTokenRequest } from "../auth/auth-provider";

export const AppList: React.FunctionComponent = () => {
  const [apps, setApps] = React.useState<IApp[]>([]);
  const [loadingApps, setLoadingApps] = React.useState(false);
  const [itemSelected, setItemSelected] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<Guid>();
  const [hideDialog, {toggle: toggleHideDialog}] = useBoolean(true)
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();

  if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
  }
  const selection = new Selection({
    onSelectionChanged: () => {
      if(selection.getSelection().length > 0) {
        const currItem = selection.getSelection()[0] as IApp;
        setSelectedItem(currItem.appId);
        setItemSelected(true);
      }
      else {
        setItemSelected(false);
      }
    }
  });
  const modalPropsStyles = { main: { maxWidth: 450 } };
  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Delete App',
    subText: 'Do you really want to delete the app?',
  };
  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles,
    }),
    [],
  );

  const getApps = async () => {
    var acc = instance.getActiveAccount();
    if(acc){
      var token = 
              await instance.acquireTokenSilent({
              ...adminTokenRequest,
              account: acc
          }).then((response) =>{
              return  response.accessToken;
          });
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };

      setLoadingApps(true);
      AppApi
        .get("/", config)
        .then((res) => {
          setLoadingApps(false);
          setApps(res.data);
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };


  async function handleDelete(){
    var acc = instance.getActiveAccount();
    if(acc){
      var token = 
              await instance.acquireTokenSilent({
              ...adminTokenRequest,
              account: acc
          }).then((response) =>{
              return  response.accessToken;
          });
      const config = {
          headers: { Authorization: `Bearer ${token}` }
      };
      AppApi
        .delete("/" + selectedItem, config)
        .then((res) => {
          setApps(apps.filter( app => app.appId !== selectedItem));
          setSelectedItem(Guid.createEmpty());
          toggleHideDialog();
        })
      }
  }

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: "newItem",
      text: "New",
      iconProps: { iconName: "Add" },
      href: "/Admin/New",

    },
    {
      key: "deleteItem",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      disabled: !itemSelected,
      onClick: () => toggleHideDialog(),
    },
    {
      key: "editItem",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      disabled: !itemSelected,
      href: "/Admin/Apps/" + selectedItem,
    },
  ];

  const columns: IColumn[] = [
    {
      key: "column1",
      name: "Thumbnail Url",
      fieldName: "thumbnailUrl",
      isIconOnly: true,
      minWidth: 80,
      maxWidth: 100,
      onRender: (item: IApp) => {
        return (
          <Image
            src={process.env.REACT_APP_APPSIMAGE_API_PATH +"/app/" + item.appId}
            width={50}
            height={50}
            imageFit={ImageFit.cover}
          />
        );
      },
    },
    {
      key: "column2",
      name: "Title",
      fieldName: "title",
      isSorted: true,
      minWidth: 210,
      isResizable: true,
      data: "string",
    },
    {
      key: "column3",
      name: "App Url",
      fieldName: "url",
      minWidth: 210,
      maxWidth: 350,
      onRender: (item: IApp) => {
        return <Link href="#">{item.url}</Link>;
      },
    },
  ];

  React.useEffect(() => {
    getApps();
    setItemSelected(false);
  }, []);

  return (
    <>
      <CommandBar items={commandBarItems} />
      {loadingApps ? (
        <Spinner
          size={SpinnerSize.large}
          labelPosition="top"
          label="Loading apps..."
        />
      ) : null}
      <Dialog
      hidden={hideDialog}
      onDismiss={toggleHideDialog}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}>
      <DialogFooter>
          <PrimaryButton onClick={toggleHideDialog} text="Cancel" />
          <DefaultButton onClick={handleDelete} text="Delete" />
        </DialogFooter>
      </Dialog>
      <MarqueeSelection selection={selection}>
        <DetailsList
          items={apps}
          columns={columns}
          selection={selection}
          selectionMode={SelectionMode.single}
          selectionPreservedOnEmptyClick={true}
        />
      </MarqueeSelection>
    </>
  );
};
