import * as React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Redirect } from "react-router";

export const Home: React.FunctionComponent = () => {


  return (
    <>
      <AuthenticatedTemplate>
        <Redirect to="/Dashboard" />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h2>You are not logged in</h2>
        <p>
          Please login to the portal to get the list of available applications.
        </p>
      </UnauthenticatedTemplate>
    </>
  );
};
