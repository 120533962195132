import { Configuration, SilentRequest, RedirectRequest, LogLevel } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AZUREADB2C_APP_CLIENT_ID || '', 
        authority: process.env.REACT_APP_AZUREADB2C_AUTHORITY, 
        redirectUri: process.env.REACT_APP_AZUREADB2C_RETURNURL, 
        knownAuthorities: [process.env.REACT_APP_AZUREADB2C_KNOWNAUTHORITIES!],
    },
    cache: {
        cacheLocation: "sessionStorage", 
        storeAuthStateInCookie: true, 
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level, message, containsPii) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {		
                    case LogLevel.Error:		
                        console.error(message);		
                        return;		
                    case LogLevel.Info:		
                        //console.info(message);		
                        return;		
                    case LogLevel.Verbose:		
                        //console.debug(message);		
                        return;		
                    case LogLevel.Warning:		
                        console.warn(message);		
                        return;		
                }
            },	
            piiLoggingEnabled: false	
        }	
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: ["openid", "profile", "offline_access", process.env.REACT_APP_AZUREADB2C_SCOPE!]
};

export const passwordResetRequest: RedirectRequest = {
    scopes: ["openid", "profile", "offline_access", process.env.REACT_APP_AZUREADB2C_SCOPE!],
    authority: process.env.REACT_APP_AZUREADB2C_PWDRESET_AUTORITY
};

export const tokenRequest: SilentRequest = {
    scopes: [process.env.REACT_APP_AZUREADB2C_SCOPE!]
};
export const adminTokenRequest: SilentRequest = {
    scopes: [process.env.REACT_APP_AZUREADB2C_SCOPE!, process.env.REACT_APP_AZUREADB2C_ADMIN_SCOPE!]
};


