import * as React from 'react';
import { IApp } from '../interfaces/IApp';

export const AppCard: React.FunctionComponent<IApp> = (props: IApp) => {
    function openApp() {
        
        if(props.url.length > 0)
        {  
            window.open(props.url, "_blank");
        }
    }

    return (
        <div className="he-CardWrapper">
            <div className="he-Card" role="button" onClick={openApp}>
                <div className="he-CardSection he-CardImage" style={{ backgroundImage:`url(${process.env.REACT_APP_APPSIMAGE_API_PATH +"/app/" + props.appId})` }}>
                </div>
                <div className="he-CardSection ">
                    <div className="he-CardTitle">{props.title}</div>
                    <div className="he-CardSection he-CardDescription">
                    {props.description}
                    </div>
                </div>
            </div>
        </div>
    );
};