import * as React from "react";
import { SearchBox, ISearchBoxStyles, Spinner, SpinnerSize, getHighContrastNoAdjustStyle} from '@fluentui/react';
import { useMsal } from "@azure/msal-react";
import { tokenRequest } from "../auth/auth-provider";
import { AppCard } from './app-card';
import AppApi from '../services/AppApi'
import axios from "axios";
import { useTranslation } from "react-i18next";

function reducer(state, action) {
    switch (action.type) {
      case "add":
            const contains = [...state].find(a => a.appId === action.item.appId);
            return contains ? [...state] : [...state, action.item]

      case "remove":
        return [
          ...state.slice(0, action.index),
          ...state.slice(action.index + 1)
        ];
      default:
        throw new Error();
    }
  }

export const AppCardList: React.FunctionComponent = () => {

    const { t } = useTranslation();
    const { instance } = useMsal();
    const accounts = instance.getAllAccounts();

    if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
    }

    /*const [apps, setApps] = React.useState<IApp[]>([]);  */
    const [apps, dispatch] = React.useReducer(reducer, []);
    const [loadingApps, setLoadingApps ] = React.useState(false);
    

    const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: 200 } }; 


    async function getApps() {
        var acc = instance.getActiveAccount();
        if(acc){
            var token = 
                    await instance.acquireTokenSilent({
                    ...tokenRequest,
                    account: acc
                }).then((response) =>{
                    return  response.accessToken;
                });
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            if (acc.idTokenClaims?.hasOwnProperty("groups")) {
                const groupsStr = acc.idTokenClaims["groups"] as string[];

                axios.all(groupsStr.map( group =>{
                    setLoadingApps(true);
                    AppApi
                    .get("/group/" + group, config)
                    .then(
                        (res) => {
                            var ap = res.data as any[];
                            for(let i = 0; i < ap.length; i++)
                            {
                                dispatch({ type: "add", item: ap[i] });
                            }
                        })
                    .catch(
                        (error) => {
                            console.error(error.message);
                        }
                    )
                    .finally(() => {
                        setLoadingApps(false);
                    })
                    })
                )
            }
        }
    };


    React.useEffect( () => {
        getApps();
    },[]);
    

    return (
        <>
            {loadingApps ? <Spinner size={SpinnerSize.large} labelPosition="top" label={t('loading')} /> : ""}
            <div className="he-CardContainer">
                {apps.length > 0 ? apps.map((app, index) => (
                    <AppCard key={index} description={app.description} appId={app.appId} title={app.title} url={app.url} />
                )) : <p>{t('noapps')}</p>}
            </div>
        </>
    )
}