import * as React from "react";
import { Stack, Sticky, StickyPositionType  } from "@fluentui/react";
import mainLogo from "../assets/images/henkel-logo-standalone-svg.svg";
import { PersonButton } from '../components/person-button'


const itemStyles: React.CSSProperties = {
  alignItems: 'right',
  textAlign: 'right'
}

export const Header: React.FunctionComponent<{isAdmin:Boolean}> = (props) => {

  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="ribbon">
        <Stack.Item grow>
          <a href="/">
            <img src={mainLogo} alt="Logo" />
          </a>
        </Stack.Item>
        <Stack.Item grow={6}>
        </Stack.Item>
        <Stack.Item grow style={itemStyles}>
          <PersonButton userIsAdmin={props.isAdmin} />
        </Stack.Item>
      </Stack>
    </Sticky>
  );
};
