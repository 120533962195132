import * as React from "react";
import { Redirect } from "react-router";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Guid } from "guid-typescript";
import { AppDetails } from "../components/app-details";
import { IApp } from "../interfaces/IApp";
import { Spinner, SpinnerSize } from "@fluentui/react";
import AppApi from "../services/AppApi";
import { useMsal } from "@azure/msal-react";
import { adminTokenRequest } from "../auth/auth-provider";

export const AppDetailsPage: React.FunctionComponent<{ appId: Guid, userIsAdmin:Boolean }> = (props) => {
  const [app, setApp] = React.useState<IApp>({
    appId: Guid.createEmpty(),
    title: "",
    url: "",
    description: "",
  });
  const [loadingApp, setLoadingApp] = React.useState(false);
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();
  if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
  }

  React.useEffect(() => {
    async function getDetails(){
      var acc = instance.getActiveAccount();
      if(acc){
          var token = 
                  await instance.acquireTokenSilent({
                  ...adminTokenRequest,
                  account: acc
              }).then((response) =>{
                  return  response.accessToken;
              });
          const config = {
              headers: { Authorization: `Bearer ${token}` }
          };
      AppApi
        .get("/app/" + props.appId.toString(), config)
        .then((res) => {
          setApp(res.data);
          setLoadingApp(false);
        })
        .catch((error) => {
          console.error(error.message);
        });
      }
    }
    if(!props.appId.isEmpty())
    {
      setLoadingApp(true);
      getDetails();
    }
  }, [props.appId]);

  return (
    <>
      <AuthenticatedTemplate>
        {props.userIsAdmin ? (
          <>
            {loadingApp ? (
              <Spinner
                size={SpinnerSize.large}
                labelPosition="top"
                label="Loading app details..."
              />
            ) : (
              <>
                <h1>Details for {app?.title}</h1>
                <AppDetails
                  appId={app?.appId}
                  title={app?.title}
                  url={app?.url}
                  description={app.description}
                />
              </>
            )}
          </>
          ):<Redirect to="/" />
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Redirect to="/" />
      </UnauthenticatedTemplate>
    </>
  );
};
